import styles from "./index.module.css";
import { useState, useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import tick from "../../assets/img/tick.svg";
import Order from "./Order";
import {
  isValidPhone,
  callApi,
  formatAmount,
  payApi,
  formatToken,
  PRINT_BASE_URL,
  removeCountryCode,
  STATES_MAP,
  getCardInfo,
  validateEmail,
  copyToClipboard,
} from "../../utils";
import download from "../../assets/img/download.svg";
import love from "../../assets/img/love.svg";

const OrderStage = ({ stage }) => {
  const applyBlueStyle = (passedstage) =>
    passedstage === stage ? styles.blue : null;

  return (
    <div className={styles.orderstage}>
      <div>
        <div className={[styles.stagenumber, applyBlueStyle(1)].join(" ")}>
          {stage < 2 ? 1 : <img src={tick} alt="tick" />}
        </div>{" "}
        info
      </div>
      <span className={styles.verticalline}></span>
      <div>
        <div className={[styles.stagenumber, applyBlueStyle(2)].join(" ")}>
          {stage > 2 ? <img src={tick} alt="tick" /> : 2}
        </div>{" "}
        Message
      </div>
      <span className={styles.verticalline}></span>
      <div>
        <div className={[styles.stagenumber, applyBlueStyle(3)].join(" ")}>
          3
        </div>{" "}
        Pay
      </div>
    </div>
  );
};

const OrderGift = ({ newStage, orderRef,persistedData }) => {
  const [data, setData] = useState({
    name: "",
    phone: "",
    state: "",
    meterNumber: "",
    email: "",
    amount: "",
    receiverName: "",
    receiverPhone: "",
    receiverEmail: "",
    message: "",
    paymentMethod: "card",
    meterDetails: {},
  });
  const [stage, setStage] = useState(1);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    card: "",
    expiry: "",
    cvv: "",
    pin: "",
    otp: "",
    phone: "",
    action: "CHARGE",
  });
  const [tokenDetails, setTokenDetails] = useState({
    token: "",
    payment_reference: "",
    units: "",
    convenience_fee: "",
    paymentType: "",
  });
  const [vendFailed, setVendFailed] = useState(false);
  const [sentGift, setSentGift] = useState(false);
  const [errorGift, seterrorGift] = useState();
  const [copyText, setCopyText] = useState("Tap to Copy");
  const [giftButton, setGiftButton] = useState("Send Gift");

  useEffect(() => {
    if (newStage) {
      setStage(newStage);
    }
    if(persistedData && Object.keys(persistedData).length !== 0){
      setData(persistedData);
    }
  }, [newStage,persistedData]);

  useEffect(() => {
    const persist = { data };
    window.localStorage.setItem("buypower_gift", JSON.stringify(persist));
  },[data]);

  const setExpiry = (val) => {
    let old = paymentDetails.expiry;
    let final = "";
    if (old.length > val.length) {
      final = val;
    } else {
      val = val.replace(/\D/g, "");
      if (val.length <= 1) {
        final = val;
      } else if (val.length === 2) {
        final = val + "/";
      } else {
        final = val.slice(0, 2) + "/" + val.slice(2);
      }
    }
    if (final.length >= 5) {
      const cvvInput = document.getElementById("cvv-input");
      if (cvvInput) cvvInput.focus();
    }
    return final.slice(0, 5);
  };

  const setOrderDetails = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setData({ ...data, name: value });
        break;
      case "phone":
        setData({ ...data, phone: value });
        break;
      case "state":
        setData({ ...data, state: value });
        break;
      case "meter":
        setData({ ...data, meterNumber: value });
        break;
      case "email":
        setData({ ...data, email: value });
        break;
      case "amount":
        setData({ ...data, amount: value });
        break;
      case "rname":
        setData({ ...data, receiverName: value });
        break;
      case "rphone":
        setData({ ...data, receiverPhone: value });
        break;
      case "remail":
        setData({ ...data, receiverEmail: value });
        break;
      case "message":
        if (value.length <= 90) {
          setData({ ...data, message: value });
        }
        break;
      case "cardnumber":
        let card = value.replace(/\s+/g, "");
        if (!Number.isInteger(+card)) {
          return;
        }
        let type = getCardInfo(card).type;
        if ((type === "mastercard" || type === "visa") && card.length >= 16) {
          const expiryInput = document.getElementById("expiry-input");
          if (expiryInput) expiryInput.focus();
        }
        setPaymentDetails({ ...paymentDetails, card });
        break;
      case "expiry":
        setPaymentDetails({ ...paymentDetails, expiry: setExpiry(value) });
        break;
      case "cvv":
        if (value.length <= 3) {
          setPaymentDetails({ ...paymentDetails, cvv: value });
        }
        break;
      case "pin":
        setPaymentDetails({ ...paymentDetails, pin: value });
        break;
      case "paymentphone":
        setPaymentDetails({ ...paymentDetails, phone: value });
        break;
      case "paymenttoken":
        setPaymentDetails({ ...paymentDetails, otp: value });
        break;
      default:
        return;
    }
  };

  const disableBack = () => {
    if (loading && stage === 3) {
      return styles.disabled;
    }
    return stage === 1 ? styles.disabled : "";
  };

  const processOrderStage = useCallback(() => {
    setLoading(true);
    setErrorMessage();
    if (stage === 1) {
      const { name, phone, state, meterNumber, amount, email } = data;
      if (!name || !phone || !state || !meterNumber || !amount || !email) {
        setErrorMessage("Please fill in all details");
        setLoading(false);
        return;
      }
      if (meterNumber.length !== 13 && meterNumber.length !== 11) {
        setErrorMessage("Service available to only prepaid meters");
        setLoading(false);
        return;
      }
      if (!isValidPhone(removeCountryCode(phone))) {
        setErrorMessage("Invalid Phone Number");
        setLoading(false);
        return;
      }
      if (state === "-inf") {
        setErrorMessage("Please select State");
        setLoading(false);
        return;
      }
      if (!validateEmail(email)) {
        setErrorMessage("invalid Email");
        setLoading(false);
        return;
      }
      let orderData = {
        discoId: state,
        meterNo: meterNumber,
        amount,
        paymentType: "CARD",
        phone: removeCountryCode(phone),
        channel: "GIFT",
      };
      callApi("/express/order", orderData, "post")
        .then((res) => {
          console.log("the response from express order", res);
          setData({ ...data, meterDetails: res });
          setStage(stage + 1);
          setLoading(false);
        })
        .catch((e) => {
          // console.error(e)
          setErrorMessage(e.data.message);
          setLoading(false);
          return;
        });
    }

    if (stage === 2) {
      const { receiverEmail, receiverPhone, message, receiverName } = data;
      if (!receiverPhone || !message || !receiverName) {
        setErrorMessage("Please fill in all details");
        setLoading(false);
        return;
      }
      if (!isValidPhone(removeCountryCode(receiverPhone))) {
        setErrorMessage("Invalid Phone Number");
        setLoading(false);
        return;
      }
      if (receiverEmail && !validateEmail(receiverEmail)) {
        setErrorMessage("invalid Email");
        setLoading(false);
        return;
      }
      setLoading(false);
      setStage(stage + 1);
    }

    if (stage === 3) {
      const { state, meterDetails, email } = data;
      const { card, expiry, cvv, action } = paymentDetails;
      const splitExpiry = expiry.split("/");
      let paymentPayload = {
        type: "CARD",
        amount: meterDetails.total,
        action,
        disco: STATES_MAP[state],
        email,
        ref: meterDetails.orderId,
        card: {
          number: card,
          cvv,
          expiry_month: splitExpiry[0],
          expiry_year: splitExpiry[1],
        },
      };
      switch (paymentDetails.action) {
        case "PIN":
          paymentPayload.pin = paymentDetails.pin;
          break;
        case "PHONE":
          paymentPayload.phone = paymentDetails.phone;
          break;
        case "OTP":
          paymentPayload.otp = paymentDetails.otp;
          break;
        default:
      }
      payApi("/charge", paymentPayload, "post")
        .then((res) => {
          setPaymentDetails({ ...paymentDetails, action: res.data.action });
          if (res.data.action === "SUCCESS") {
            setStage(4);
            return;
          }

          if (res.data.action === "URL") {
            if (res.data.ref) {
              // setData({...data,meterDetails:{...data.meterDetails,orderId:res.data.ref}});
              meterDetails.orderId = res.data.ref;
            }
            const urlObj = new URL(res.data.url);
            urlObj.searchParams.append(
              "redirect_to",
              `https://gift.buypower.ng/confirm?ref=${meterDetails.orderId}`
            );
            var a = urlObj.href;
            console.log("URL is :", a);
            window.location.href = a;
          }

          if (res.data.action === "FAILED") {
            setErrorMessage(res.data.message);
            setLoading(false);
          }

          if (res.data.action !== "URL") {
            setLoading(false);
          }
        })
        .catch((e) => {
          setErrorMessage(e.data.message);
          setLoading(false);
          return;
        });
    }

    if (stage === 4) {
      setVendFailed(false);
      const orderId = data.meterDetails.orderId;
      callApi(`/transaction/vend?ref=${orderRef || orderId}`)
        .then((res) => {
          const {
            token,
            payment_reference,
            units,
            convenience_fee,
            paymentType,
          } = res.transaction;
          setTokenDetails({
            ...tokenDetails,
            token,
            payment_reference,
            units,
            convenience_fee,
            paymentType,
          });
          setStage(stage + 1);
        })
        .catch((e) => {
          // console.error(e)
          setErrorMessage(e.data.message);
          setLoading(false);
          setVendFailed(true);
        });
    }
    setErrorMessage("");
  }, [data, stage, tokenDetails, paymentDetails, orderRef]);

  const goBack = () => {
    setStage(stage - 1);
    setLoading(false);
    setErrorMessage("");
  };

  const disableCta = () => {
    if (stage > 3) {
      return styles.disableCta;
    }
  };
  const processOrderButton = () => {
    let text = "Continue";
    if (loading) {
      return "Please wait ...";
    }
    if (stage === 3 && data.paymentMethod === "card") {
      text = `Pay ${formatAmount(data.meterDetails.total)}`;
    }
    // if (stage === 3 && loading ) {
    //   text = `Please wait ...`;
    // }
    return text;
  };

  const copyToken = (e) => {
    setCopyText("Copied");
    copyToClipboard(tokenDetails.token);
  };

  const retryPayment = () => {
    setStage(3);
    setVendFailed(false);
  } 

  const sendGift = () => {
    setGiftButton("Please wait...");
    seterrorGift();
    const { message, receiverPhone, receiverEmail, receiverName } = data;
    let payload = {
      reference: orderRef || data.meterDetails.orderId,
      message,
      recipientPhone: receiverPhone,
      recipientEmail: receiverEmail,
      receiverName,
    };
    window.gtag('event', 'purchase_gift_complete', {
      'event_value':data.meterDetails.total
    });
    callApi("/gift/send", payload, "post")
      .then((res) => setSentGift(true))
      .catch((e) => {
        setGiftButton("Send Gift");
        seterrorGift(e.data.message);
      });
  };
  const { discoCode } = data.meterDetails || "";
  return (
    <>
      {stage < 5 && (
        <div className={styles.wrapper}>
          <p className={styles.header}>
            {stage === 1 && "Enter the following details to begin"}
            {stage === 2 && "Leave a message"}
            {stage >= 3 && "Complete Order"}
          </p>
          <OrderStage stage={stage} />
          {errorMessage ? (
            <p className={styles.errorMessage}>{errorMessage}</p>
          ) : null}
          <Order
            setOrderDetails={setOrderDetails}
            stage={stage}
            data={data}
            paymentDetails={paymentDetails}
            processOrderStage={processOrderStage}
            vendFailed={vendFailed}
            retryPayment={retryPayment}
          />
          <div className={[styles.button_cta, disableCta()].join(" ")}>
            <button
              className={[styles.btn__light, disableBack()].join(" ")}
              onClick={goBack}
            >
              Back
            </button>
            <button
              className={styles.btn__green}
              onClick={processOrderStage}
              disabled={loading}
            >
              {processOrderButton()}
            </button>
          </div>
        </div>
      )}
      {stage === 5 && (
        <div className={styles.recieptWrapper}>
          <div className={styles.orderReciept}>
            <div className={styles.recieptHead}>
              <span>Thanks for choosing BuyPower.ng</span>
              <span className={styles.grey}>
                Ref: {data.meterDetails.orderId}
              </span>
              <a
                href={`${PRINT_BASE_URL}/${data.meterDetails.orderId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={styles.recieptDownload}>
                  <img src={download} alt="download" />
                  Download Receipt
                </span>
              </a>
            </div>
            <div>
              <img src={love} alt="love" />
            </div>
          </div>
          {sentGift ? (
            <p className={styles.giftsent}>
              Your message has been sent to your loved one
            </p>
          ) : null}
          {errorGift && <p className={styles.errorMessage}>{errorGift}</p>}
          <div className={styles.recieptDetails}>
            <p>Order Summary</p>
            <div className={styles.recieptSummary}>
              <div className={styles.recieptSummary_left}>
                <img src={`/img/discos/${discoCode}.png`} alt={discoCode} />
                <div>
                  <span>
                    {data.meterDetails.meter}&nbsp;&bull;&nbsp;
                    {data.meterDetails.transactionType.toLowerCase()}
                  </span>
                  <span className={styles.light}>
                    {data.meterDetails.name.toLowerCase()}
                  </span>
                  <span className={styles.light}>
                    {data.meterDetails.address.toLowerCase()}
                  </span>
                </div>
              </div>
              <div className={styles.recieptSummary_right}>
                <div>
                  <span className={styles.light}>Payment Type</span>
                  <span>{tokenDetails.paymentType}</span>
                </div>
                <div>
                  <span className={styles.light}>Total</span>
                  <span>{formatAmount(data.meterDetails.total)}</span>
                </div>
                <div>
                  <span className={styles.light}>Vend Amount</span>
                  <span>{formatAmount(data.amount)}</span>
                </div>
                <div>
                  <span className={styles.light}>Charges</span>
                  <span>{formatAmount(tokenDetails.convenience_fee)}</span>
                </div>
                <div>
                  <span className={styles.light}>Units</span>
                  <span>{tokenDetails.units}</span>
                </div>
              </div>
            </div>
            <div className={styles.tokenInfo}>
              <span>Here’s the token</span>
              <span className={styles.token}>
                {formatToken(tokenDetails.token)}
              </span>
              <span onClick={copyToken}>{copyText}</span>
            </div>
            <div className={styles.book}>
              <span>
                Dear {data.name},<br />
                <br />
              </span>
              <span>
                You have successfully purchased units for your loved one. Click
                the button below to send as a gift.
              </span>
            </div>
            {!sentGift && (
              <button className={styles.btn__gift} onClick={sendGift}>
                {giftButton}
              </button>
            )}
            {sentGift ? (
              <p className={styles.giftsent}>
                Your message has been sent to your loved one
              </p>
            ) : null}
            <span className={styles.light}>
              *For your convenience, we have also sent a copy of the token to your phone
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(OrderGift);
