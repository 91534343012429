import './App.css'
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';

const  App = () => {
  return (
    <>
      <div className="App">
        <Header />
        <Main />
      </div>
      <Footer />
    </>
  );
}

export default App;
