import {useEffect} from 'react'
import styles from './index.module.css';
import {formatCard} from '../../utils';
import Loader from '../Loader/Loader';

const OrderComplete = ({processOrderStage,vendFailed,retryPayment}) => {

  useEffect(() => {
    processOrderStage();
  },[processOrderStage])

  return (
    <div className={styles.ordercomplete}>
      {!vendFailed && <p>Please wait while we verify your payment.</p>}
      <br />
      {vendFailed && <p>Dear Customer, we could not verify your payment. </p>}
      <br />
      { 
        vendFailed ? 
        <div className={styles.retry}>
          <button onClick={retryPayment}>Please try again</button>
        </div> :
        <Loader />
      }
    </div>
  )
}
const OrderProcessing = ({setOrderDetails,data,paymentDetails}) => {
  const handleChange = setOrderDetails;
  const {paymentMethod} = data;
  return (
    <div>
      {
        paymentMethod === "card" &&
        <>
        <div className={styles.card}>
          <div className={styles.cardNumber}>
            <label>Card Number</label>
            <input 
              name="cardnumber" 
              placeholder="Enter Card Number" 
              type="text" 
              required 
              onChange={handleChange} 
              value={formatCard(paymentDetails.card)}
            />
          </div>
          <div>
            <div className={styles.cardExpiry}>
              <label>Expiry Date</label>
              <input 
                name="expiry" placeholder="MM/YY" 
                type="text" required 
                onChange={handleChange} 
                value={paymentDetails.expiry}
                id="expiry-input"
              />
            </div>
            <div className={styles.cardCvv}>
              <label>CVV</label>
              <input name="cvv" placeholder="CVV" 
                type="number" required 
                onChange={handleChange} 
                value={paymentDetails.cvv}
                id="cvv-input"
              />
            </div>
          </div>
        </div>
        { paymentDetails.action === "PIN" && 
          <div className={styles.mg_btm}>
            <label>Enter your pin</label>
            <input name="pin"
              type="number" required
              onChange={handleChange}
              value={paymentDetails.pin}
            />
          </div>
        }
        { paymentDetails.action === "OTP" &&
          <div className={styles.mg_btm} >
            <label>Enter the token sent to your phone or email</label>
            <input name="paymenttoken"
              type="number" required
              onChange={handleChange}
              value={paymentDetails.paymenttoken}
            />
          </div>
        }
        { paymentDetails.action === "PHONE" &&
          <div className={styles.mg_btm} >
            <label>Enter your phone number</label>
            <input name="paymentphone"
              type="number" required
              onChange={handleChange}
              value={paymentDetails.phone}
            />
          </div>
        }
        </>
      }      
    </div>
  )
}


const CompleteOrder = (props) => {
  if(props.stage === 3) {
    return <OrderProcessing {...props}/>
  }
  return < OrderComplete processOrderStage={props.processOrderStage} vendFailed={props.vendFailed} retryPayment={props.retryPayment}/>
}
export default CompleteOrder;