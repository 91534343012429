import styles from './index.module.css';
import information from '../../assets/img/information.svg';
import {formatAmount} from '../../utils';


const MessageInfo = ({handleChange,data}) => {
  const {meterDetails} = data;
  const {discoCode} = meterDetails;
  return (
    <div className={styles.message}>
      <div className={styles.meterdetails}>
        <div className={styles.meterdetails__left}>
          <img src={`/img/discos/${discoCode}.png`} alt={discoCode}/>
          <div className={styles.meteraddress}>
            <span className={styles.bold}>
              {meterDetails.meter}&nbsp;&bull;&nbsp;{meterDetails.transactionType.toLowerCase()}
            </span>
            <span className={styles.light}>
              {meterDetails.name.toLowerCase()}&nbsp; &bull;&nbsp;{meterDetails.address.toLowerCase()}
            </span>
          </div>
        </div>
        <div className={styles.meteramount}>
          <span className={styles.light}>
            Amount Due &nbsp; <img className={styles.charge}src={information} alt="information" />
            <span className={styles.commission}><span>+ ₦100 </span>Service Charge</span>
          </span>
          <span className={styles.totalAmount}>{formatAmount(meterDetails.total)}</span>
        </div>
      </div>
      <form className={styles.orderinfoform}>
      <div className={styles.split}>
        <div>
          <label>Receivers's Name</label>
          <input name="rname" placeholder="Name" type="text" 
            required onChange={handleChange} value={data.receiverName}
          />
        </div>
        <div>
          <label>Receiver's Phone</label>
          <input name="rphone" placeholder="Phone Number" type="text" 
            required onChange={handleChange} value={data.receiverPhone}
          />
        </div>
      </div>
      <div className={styles.email}>
        <label>Receiver's Email (optional) </label>
        <input name="remail" placeholder="Enter Receiver's Email" 
          type="email" required onChange={handleChange} value={data.receiverEmail}
          id="remail"
        />
      </div>
      <div className={styles.message}>
        <label>Message</label>
        <textarea name="message" placeholder="Type the message you would like to send" 
          type="text" required onChange={handleChange} 
          value={data.message}
        />
        <span className={styles.messageLimit}>{`${data.message.length}/90`}</span>
      </div>
    </form>
    </div>
  )
}

export default MessageInfo;