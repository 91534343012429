import styles from './footer.module.css';
// import facebook from '../../assets/img/facebook.svg';
// import instagram from '../../assets/img/instagram.svg';
// import twitter from '../../assets/img/twitter.svg';
// import youtube from '../../assets/img/youtube.svg';

const Footer = () => {
  return (
    <div className={styles.background}>
      <div className={styles.footer}>
        <div className={styles.footer__img}>
          <a href="https://buypower.ng/merchant" target="_blank" rel="noreferrer">
            Merchant
            {/* <img src={youtube} alt="youtube" /> */}
          </a>
          <a href="https://www.iubenda.com/privacy-policy/8022132" target="_blank" rel="noreferrer">
            Privacy Policy
            {/* <img src={instagram} alt="instagram" /> */}
          </a>
          <a href="https://buypower.ng/content/merchants/tnc" target="_blank" rel="noreferrer">
            Terms
            {/* <img src={twitter} alt="twitter" /> */}
          </a>
          <a href="https://conversations.buypower.ng/" target="_blank" rel="noreferrer">
            Contact Us
            {/* <img src={facebook} alt="facebook" /> */}
          </a>
        </div>
        <div>© BuyPower Inc | All Rights Reserved</div>
      </div>
    </div>
  )
}

export default Footer;