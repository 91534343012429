import axios from 'axios';
import ccType from 'credit-card-type';


export const STATES = [
  { id: 1, name: 'ABUJA' },
  { id: 24, name: 'ANAMBRA' },
  { id: 16, name: 'AKWA-IBOM' },
  { id: 9, name: 'KADUNA' },
  { id: 7, name: 'BAUCHI' },
  { id: 14, name: 'BAYELSA' },
  { id: 6, name: 'BENUE' },
  { id: 15, name: 'CROSS-RIVER' },
  { id: 23, name: 'ENUGU' },
  { id: 8, name: 'GOMBE' },
  { id: 25, name: 'IMO' },
  { id: 11, name: 'KEBBI' },
  { id: 3, name: 'KOGI' },
  { id: 22, name: 'KWARA' },
  { id: 18, name: 'LAGOS-EKO' },
  { id: 17, name: 'LAGOS-IKEJA' },
  { id: 4, name: 'NASSARAWA' },
  { id: 2, name: 'NIGER' },
  { id: 21, name: 'OGUN' },
  { id: 20, name: 'OSUN' },
  { id: 19, name: 'OYO' },
  { id: 5, name: 'PLATEAU' },
  { id: 13, name: 'RIVERS' },
  { id: 10, name: 'SOKOTO' },
  { id: 12, name: 'ZAMFARA' },
  { id: 36, name: 'KANO' },
  { id: 37, name: 'JIGAWA' },
  { id: 38, name: 'KATSINA' },
];

export const STATES_MAP = {
  1: 'ABUJA' ,
  24: 'ANAMBRA' ,
  16: 'AKWA-IBOM' ,
  9: 'KADUNA' ,
  7: 'BAUCHI' ,
  14: 'BAYELSA' ,
  6: 'BENUE' ,
  15: 'CROSS-RIVER' ,
  23: 'ENUGU' ,
  8: 'GOMBE' ,
  25: 'IMO' ,
  11: 'KEBBI' ,
  3: 'KOGI' ,
  22: 'KWARA' ,
  18: 'LAGOS-EKO' ,
  17: 'LAGOS-IKEJA' ,
  4: 'NASSARAWA' ,
  2: 'NIGER' ,
  21: 'OGUN' ,
  20: 'OSUN' ,
  19: 'OYO' ,
  5: 'PLATEAU' ,
  13: 'RIVERS' ,
  10: 'SOKOTO' ,
  12: 'ZAMFARA' ,
  36: 'KANO' ,
  37: 'JIGAWA' ,
  38: 'KATSINA' ,
};

export const commaNumber = (num = 0) => Number(num).toLocaleString('en-US');
export const formatAmount = (num, space = true) => `₦${space ? ' ' : ''}${commaNumber(num)}`;

export const isValidPhone = (n = '') => {
  let number;
  const pattern = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g;

  if (!n || n.length < 5) return false;

  if (typeof n === 'number') {
    // numbers never begin with 0, force this to become a string
    number = '0' + n;
  } else if (typeof n === 'string') {
    number = n;
  } else {
    return false;
  }

  // remove all whitespace(s) before running test
  number = number.replace(/\s+/g, '');
  return pattern.test(number);
};


const request = axios.create({
  baseURL: '/client',
  withCredentials: true,
  timeout: 150000, // 2 Minutes +
});

const payment = axios.create({
  baseURL: '/client/pay',
  withCredentials: true,
  timeout: 150000, // 2 Minutes +
});

export const callApi = (endpoint, data = {}, method = 'get') => {
  method = ['get', 'post', 'delete'].includes(method.toLowerCase()) ? method.toLowerCase() : 'post'
  return new Promise((resolve, reject) => {
    const sendData = method === 'get' || method === 'delete' ? { params: data } : { ...data };
    request[method](endpoint, sendData)
      .then(res => resolve(res.data))
      .catch(err => {
        if (err.response) {
          // Response from server w/ error
          const { data } = err.response; 
          if (typeof data === 'string') {
            err.response.data = { message: 'Sorry, an unexpected error occurred while performing that request' }
          }
          return reject(err.response);
        } else {
          // We would normally reject with an Error object here
          // But we use destructuring to display error toaster and the message object is lost in the process
          // Return a direct object with message and title properties
          return reject({
            title: '❌ Network Error',
            message: 'Please check your internet connection and try again.',
          });
        }
      });
  });
};

export const payApi = (endpoint, data = {}, method = 'get') => {
  method = ['get', 'post', 'delete'].includes(method.toLowerCase()) ? method.toLowerCase() : 'post'
  return new Promise((resolve, reject) => {
    const sendData = method === 'get' || method === 'delete' ? { params: data } : { ...data };
    payment[method](endpoint, sendData)
      .then(res => resolve(res))
      .catch(err => {
        if (err.response) {
          // Response from server w/ error
          const { data } = err.response; 
          if (typeof data === 'string') {
            err.response.data = { message: 'Sorry, an unexpected error occurred while performing that request' }
          }
          return reject(err.response);
        } else {
          // We would normally reject with an Error object here
          // But we use destructuring to display error toaster and the message object is lost in the process
          // Return a direct object with message and title properties
          return reject({
            title: '❌ Network Error',
            message: 'Please check your internet connection and try again.',
          });
        }
      });
  });
};

export const formatToken = str => {
  if (!str) return '';
  str = String(str);
  if (str.indexOf(' ') !== -1) return str;
  str = str.toString();
  let totalSplit = Math.floor(str.length / 4);
  let start = 0;
  let end = 4;
  let output = '';
  for (let i = 0; i < totalSplit; i++) {
    output += str.slice(start, end);
    output += i === totalSplit - 1 ? '' : ' ';
    start += 4;
    end += 4;
  }
  return output;
};

export const PRINT_BASE_URL =  "https://print.buypower.ng/receipt"
export const removeCountryCode = (phone) => phone.replace(/^(\+?234|0)?/, 0);



// Credit Card Stuff
const CARD_GAPS = [4, 8, 12];
// Get card type and separator gaps
export const getCardInfo = (num = '') => {
  const info = ccType(num)[0];
  if (info) {
    const isVerve = info.type === 'maestro';
    return {
      type: isVerve ? 'verve' : info.type,
      gaps: isVerve ? CARD_GAPS.concat(16) : info.gaps || CARD_GAPS,
    };
  } else
    return {
      type: 'none',
      gaps: CARD_GAPS,
    };
};

// Format Credit Card
export const formatCard = (card,gaps = CARD_GAPS,type = "mastercard") => {
  if(card.length > 3){
    gaps = getCardInfo(card).gaps;
    type = getCardInfo(card).type;
  }
  let str = '';
  for (let i = 0; i < card.length; i++) {
    if (gaps.includes(i)) {
      str += ' ';
    }
    str += card[i];
  }
  return str;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};
