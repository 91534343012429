import styles from './loader.module.css'
import loader from '../../assets/img/loader.svg'
const Loader = () => (
  <div className={styles.loader}>
    <img src={loader} alt="loader" />
  </div>
  
)


export default Loader;