import styles from './header.module.css';
import logo from '../../assets/img/logo.svg'
import arrow from '../../assets/img/arrowlink.svg'

const Header = () => {
	return (
		<nav className={styles.header}>
			<div>
				<a href="/"><img className={styles.logo} src={logo} alt="Gift" /></a>
			</div>
			<div>
				<a className={styles.link} href="https://buypower.ng/" target="__blank">
					Go to BuyPower.ng &nbsp;<img className={styles.arrow} src={arrow} alt="->" />
				</a>
			</div>
		</nav>
	)
}

export default Header;