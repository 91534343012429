import styles from './main.module.css';
import OrderGift from '../OrderGift/OrderGift'
import {Switch,withRouter,Route} from "react-router-dom";
import {useEffect,useState} from "react";
import Loader from '../Loader/Loader'


const OrderComplete = withRouter(({history,location,update}) => {
  
  useEffect(() => {
    const refVal = location.search.split("?")[1].split("=")[1];
    const persist = window.localStorage.getItem("buypower_gift");
    let persitedValues = JSON.parse(persist).data || {};
    update(refVal,persitedValues);
    history.push("/");
  },[history,location,update])

  return (
    <div className={styles.ordercomplete}>
      < Loader />
    </div>
  )
})

const Main = () => {

  const [orderRef, setOrderRef] = useState();
  const [newStage,setNewStage] = useState();
  const [persistedData,setPersistedData] = useState();

  const update = (val,data) => {
    setOrderRef(val);
    setPersistedData(data);
    setNewStage(4);
  }

  return (
    <div className={styles.main}>
      <h1>
        Keep the lights on for your <br /><span className={styles.green}>loved ones</span>
      </h1>
      <Switch>
        <Route path="/confirm" component={() => <OrderComplete update={(val,data) => update(val,data)} />} />
        <Route path="/" component={() => <OrderGift orderRef={orderRef} newStage={newStage} persistedData={persistedData} /> } />
      </Switch>
    </div>
  )
}

export default Main;