import styles from './index.module.css'
import {STATES} from '../../utils'

const OrderInfo = ({handleChange,data}) => {
  return (
    <form className={styles.orderinfoform}>
      <div className={styles.split}>
        <div>
          <label>Your Name</label>
          <input name="name" placeholder="Name" type="text" required onChange={handleChange} value={data.name}/>
        </div>
        <div>
          <label>Your Phone</label>
          <input name="phone" placeholder="Phone Number" type="text" required onChange={handleChange} value={data.phone}/>
        </div>
      </div>
      <div className={styles.amount}>
        <label>Your Email</label>
        <input name="email" type="email" placeholder="Enter your Email" onChange={handleChange} value={data.email} id="email"/>
      </div>
      <div className={[styles.split,styles.flex_col].join(' ')}>
        <div>
          <label>Receiver’s State</label>
          <select
            name="state"
            required
            onChange={handleChange}
            value={data.state}
          >
            <option value="-inf">Select State</option>
            {STATES.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Receiver’s Meter Number</label>
          <input name="meter" type="number" placeholder="Prepaid Meter Number" onChange={handleChange} value={data.meterNumber}/>
        </div>
      </div>
      <div className={styles.amount}>
        <label>Amount</label>
        <input name="amount" type="number" placeholder="Enter the amount you would like to buy" onChange={handleChange} value={data.amount}/>
      </div>
    </form>
  )
}

export default OrderInfo