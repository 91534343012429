import OrderInfo from './OrderInfo';
import MessageInfo from './MessageInfo';
import CompleteOrder from './CompleteOrder';


const Order = (props) => {
  const {stage,setOrderDetails,data} = props;
  if(stage === 1) {
    return <OrderInfo handleChange={setOrderDetails} data={data} />
  } 
  if(stage === 2) {
    return <MessageInfo handleChange={setOrderDetails} data={data}/>
  } 
  else{
    return <CompleteOrder {...props} />
  }
  
}

export default Order;